import axios from "axios";
import {environment} from "src/environement/environment";
import {
    CREATENEWCUSTOMER, GET_ALL_BUSINESS, GET_ALL_USERS, GET_STATISTICS_ADMIN, GET_USERS_ONLINE, NOTIFICATIONSENDED,
} from "./AccountActionType";
import swal from "sweetalert";
import {formatToCapitalize} from "src/utils/formatRowValue";
import {GET_MY_BUSINESS} from "../../../dashboard/analytics/service/DashboardServiceActionTypes";

export const getAllUsers = (page, limit) => async (dispatch) => {
    try {
        const response = await axios.get(`${environment.baseUrl}/dashboard_admin/business/profile/all?page=${page}&limit=${limit}`);

        dispatch({
            type: GET_ALL_USERS, payload: {
                currentPage: response.data.currentPage,
                totalPages: response.data.totalPages,
                totalElements: response.data.totalElements,
                data: response.data.data,
            },
        });
    } catch (e) {
        console.log("INVALID", e);
    }
};

export const getAllUsersByAccountType = (page, limit, accountType) => async (dispatch) => {
    try {
        const response = await axios.get(`${environment.baseUrl}/dashboard_admin/users/byAccountType?page=${page}&limit=${limit}&accountType=${accountType}`);


        dispatch({
            type: GET_ALL_USERS, payload: {
                currentPage: response.data.currentPage,
                totalPages: response.data.totalPages,
                totalElements: response.data.totalElements,
                data: response.data.data,
            },
        });
    } catch (e) {
        console.log("INVALID", e);
    }
};

export const lockManagementUser = (payload) => async (dispatch) => {
    try {
        const response = await axios.put(`${environment.baseUrl}/dashboard_admin/user/updateUserAccountState`, payload);


        swal({
            title: "Success", text: response.data.message, icon: "success", button: "OK",
        });
    } catch (e) {
        console.log("INVALID", e);
    }
};

export const getAllUsersByCompanyName = (page, limit, companyName) => async (dispatch) => {
    try {
        const response = await axios.get(`${environment.baseUrl}/dashboard_admin/users/allByCompanyName?page=${page}&limit=${limit}&companyName=${companyName}`);


        dispatch({
            type: GET_ALL_USERS, payload: {
                currentPage: response.data.currentPage,
                totalPages: response.data.totalPages,
                totalElements: response.data.totalElements,
                data: response.data.data,
            },
        });
    } catch (e) {
        console.log("INVALID", e);
    }
};

export const updateUserInfo = (payload, userRegistrationUuid) => async (dispatch) => {
    try {
        const response = await axios.put(`${environment.baseUrl}/dashboard_admin/business/account/updateUserPersonalData/${userRegistrationUuid}`, payload);


        swal({
            title: "Success", text: response.data.message, icon: "success", button: "OK",
        });
        dispatch({
            type: GET_ALL_USERS, payload: {
                data: response.data,
            },
        });
    } catch (e) {
        console.log("INVALID", e);
        await swal({
            title: "Error", text: e.response.data.message, icon: "error", button: "OK",
        });
    }
};


export const sendGlobalNotification = (payload, navigate) => async (dispatch) => {
    try {
        const response = await axios.post(`${environment.baseUrl}/dashboard_admin/notification/global`, payload);


        dispatch({
            type: NOTIFICATIONSENDED, payload: {
                data: response.data,
            },
        });

        swal({
            title: "Success", text: response.data.message, icon: "success", button: "OK",
        });

        navigate("/admin/dashboard");
        return false;
    } catch (e) {
        if (e.response != null) {
            swal({
                title: "Richiesta non riuscita",
                text: ` ${formatToCapitalize(e.response.data.message)} `,
                icon: "error", // Change icon to 'error' for indicating an error
                button: "OK",
                dangerMode: true, // Set dangerMode to true to display the message with a red color
            });
        }
        console.log("INVALID", e);
        return false;
    }
};

export const updateFreeTrialService = (payload, userRegistrationUuid) => async (dispatch) => {
    try {
        const response = await axios.put(`${environment.baseUrl}/dashboard_admin/freeTrialManagement/${userRegistrationUuid}`, payload);


        swal({
            title: "Success", text: response.data.message, icon: "success", button: "OK",
        });
    } catch (e) {
        console.log("INVALID", e);
        if (e.response != null) {
            swal({
                title: "Richiesta non riuscita",
                text: ` ${formatToCapitalize(e.response.data.message)} `,
                icon: "error", // Change icon to 'error' for indicating an error
                button: "OK",
                dangerMode: true, // Set dangerMode to true to display the message with a red color
            });
        }
        console.log("INVALID", e);

        return false;
    }
};


export const getAllBusiness = (page, limit) => async (dispatch) => {
    try {
        const response = await axios.get(`${environment.baseUrl}/dashboard_admin/business/all?page=${page}&limit=${limit}`);


        dispatch({
            type: GET_ALL_BUSINESS, payload: {
                currentPage: response.data.currentPage,
                totalPages: response.data.totalPages,
                totalElements: response.data.totalElements,
                data: response.data.data,
            },
        });
    } catch (e) {
        console.log("INVALID", e);


    }
};


export const getAllBusinessByName = (page, limit, searchedKeyword) => async (dispatch) => {
    try {
        const response = await axios.get(`${environment.baseUrl}/dashboard_admin/business/${searchedKeyword}?page=${page}&limit=${limit}`);


        dispatch({
            type: GET_ALL_BUSINESS, payload: {
                currentPage: response.data.currentPage,
                totalPages: response.data.totalPages,
                totalElements: response.data.totalElements,
                data: response.data.data,
            },
        });
    } catch (e) {
        console.log("INVALID", e);


    }
};


export const registerUserByAdmin = (formData) => async (dispatch) => {
    try {
        const response = await axios.post(`${environment.baseUrl}/userRegistration/register`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });
        await swal({
            title: "Success", text: response.data.message, icon: "success", button: "OK",
        });

        return {clear: true, statusButton: false};
    } catch (e) {
        if (e.response && e.response.data && e.response.data.status === 500) {


            await swal({
                title: "Error", text: e.response.data.message, icon: "error", button: "OK",
            });

            return {clear: false, statusButton: false};
        }
        return {clear: false, statusButton: false};
    }
}


// Function to fetch getStatisticUser
export const getStatisticUser = (startDate, endDate) => async (dispatch) => {
    try {
        const response = await axios.get(`${environment.baseUrl}/dashboard_admin/business/accounts/status/byRangeDate?from=${startDate}&to=${endDate}`);
        //const response = await axios.get(`${environment.baseUrl}/dashboard_admin/business/accounts/status`);

        dispatch({
            type: GET_STATISTICS_ADMIN, payload: response.data

        });

        return response;
    } catch (e) {
        console.log("INVALID", e);
    }
};


export const getUsersOnlineByDateRange = (page, limit, from, to) => async (dispatch) => {
    try {


        const response = await axios.get(`${environment.baseUrl}/dashboard_admin/business/accounts/byRangeDate?from=${from}&to=${to}&page=${page}&limit=${limit}`);

        dispatch({
            type: GET_USERS_ONLINE, payload: {
                currentPage: response.data.currentPage,
                totalPages: response.data.totalPages,
                totalElements: response.data.totalElements,
                data: response.data.data,
            },
        });
    } catch (e) {
        console.log("INVALID", e);


    }
};


// Function to fetch My Business Data
export const getAdminData = () => async (dispatch) => {
    try {
        const response = await axios.get(`${environment.baseUrl}/dashboard_admin/admin/my_profile`);

        dispatch({
            type: GET_MY_BUSINESS, payload: response.data

        });

        return response;
    } catch (e) {
        console.log("INVALID", e);
    }
};



