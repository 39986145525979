import React, {createContext, useState, useContext, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {
    refreshUser,
    logOutAttempt,
    authenticationUser,
} from "src/modules/account/service/AuthAction";
import swal from "sweetalert";
import {formatToCapitalize} from "src/utils/formatRowValue";
import {useDispatch} from "react-redux";
import Spinner from "src/components/shared/Spinner";
import {clearCookies} from "src/utils/cookiesTools";
import {roleAdmin, roleBusiness} from "../../commons/constants/Constants";

const AuthContext = createContext(null);

export const AuthProvider = ({children}) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        const token = localStorage.getItem("refresh_token");
        if (token) {
            refreshToken();
        } else {
            setLoading(false);
        }
    }, []);

    const refreshToken = async () => {
        try {
            const {data} = await dispatch(refreshUser());

            setIsAuthenticated(true);
            setUser({roles: data.roles});
            localStorage.setItem("isAuthenticated", "true");
        } catch (error) {
            console.error("Token refresh failed:", error);
            handleAuthError(error);
        } finally {
            setLoading(false);
        }
    };

    const handleAuthError = (error) => {
        dispatch(logOutAttempt());
        setIsAuthenticated(false);
        setUser(null);
        if (error.response?.status === 402) {
            swal({
                title: "Richiesta non riuscita",
                text: formatToCapitalize(error.response.data.message),
                icon: "warning",
                button: "OK",
                dangerMode: true,
            });
        }
        navigate("/auth/login", {replace: true});
    };

    const login = async (payload) => {
        try {
            const response = await dispatch(authenticationUser(payload, navigate));

            // Check if the response has the expected data
            if (response.data.roles.includes(roleBusiness) || response.data.roles.includes(roleAdmin)) {
                setIsAuthenticated(true);
                setUser({roles: response.data.roles, ...response.data});
                localStorage.setItem("isAuthenticated", "true");
                localStorage.setItem("refresh_token", response.data.refreshToken);
                localStorage.setItem("access_token", response.data.accessToken);
            } else {
                throw new Error("User does not have the required role");
            }
        } catch (error) {
            console.error("Login failed:", error);
            if (error.response?.status === 451) {
                setError({message: error.response.data.message, status: 451});
            } else if (error.response?.data) {
                setError({message: error.response.data.message});
            } else {
                setError({message: "An unknown error occurred"});
            }
            return false;
        }
    };

    const logout = () => {
        clearCookies();
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
        localStorage.removeItem("isAuthenticated");
        localStorage.removeItem("rolesUser");
        setIsAuthenticated(false);
        setUser(null);

        navigate("/auth/login", {replace: true});
    };

    if (loading) {
        return <Spinner/>; // Or your custom loading component
    }

    return (
        <AuthContext.Provider
            value={{isAuthenticated, user, login, logout, refreshToken, error}}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
