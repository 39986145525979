import {
    GET_MY_CATEGORY, GET_SELECTED_CATEGORY
} from "src/modules/dashboard/analytics/service/DashboardServiceActionTypes";
import { clearCookies } from "src/utils/cookiesTools";

const initialState = {
    myCategory: [],


    selectedCategory: null,
};

export default function CategoryReducer(state = initialState, action) {
    switch (action.type) {
        // GET MY CATEGORY
        case GET_MY_CATEGORY:
            return {
                ...state,
                myCategory: action.payload,
            };

        case GET_SELECTED_CATEGORY:
            return {
                ...state,
                selectedCategory: action.payload,
            };

        default: {
            return state;
        }
    }
}
