export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const SUCCES = "SUCCES";
export const REFRESHTOKEN = "REFRESHTOKEN";
export const FAILURE = "FAILURE";
export const SIGNUP = "SIGNUP";
export const SIGNUPERROR = "SIGNUP_ERROR";
export const AUTHSUCCESS = "AUTH_SUCCESS";
export const FORGOTPASSWORD = "FORGOTPASSWORD";
export const RESETPASSWORD = "RESET_PASSWORD";
export const SUCCESCONFIRMATIONMAIL = "SUCCES_CONFIRMATION_MAIL";
export const FAILURECONFIRMATIONMAIL = "FAILURE_CONFIRMATION_MAIL";
export const CONFIRMATIONPROCESS = "CONFIRMATION_PROCESS";


