import {
    GET_ALL_TOPICS, GET_MY_CATEGORY, GET_SELECTED_CATEGORY, GET_SELECTED_TOPIC
} from "src/modules/dashboard/analytics/service/DashboardServiceActionTypes";
import {clearCookies} from "src/utils/cookiesTools";

const initialState = {
    // ALL USERS
    currentPage: 0, totalPages: 0, totalElements: 0, topicsList: [],

    selectedTopic: null
};

export default function TopicReducer(state = initialState, action) {
    switch (action.type) {
        //   GET ALL TOPICS
        case GET_ALL_TOPICS:
            return {
                ...state,
                currentPage: action.payload.currentPage,
                totalPages: action.payload.totalPages,
                totalElements: action.payload.totalElements,
                topicsList: action.payload.data,
            };
        case GET_SELECTED_TOPIC:
            return {
                ...state, selectedTopic: action.payload,
            };


        default: {
            return state;
        }
    }
}
