import {useNavigate} from "react-router-dom";
import {useAuth} from "./AuthContext";
import {useEffect} from "react";
import {roleAdmin, roleBusiness} from "../../commons/constants/Constants";

const AuthAdminGuard = ({children}) => {
    const authManagement = useAuth();

    const navigate = useNavigate();

    useEffect(() => {
        if (!authManagement.isAuthenticated) {
            navigate("/auth/login", {replace: true});
        } else if (authManagement.isAuthenticated && authManagement.user?.roles?.includes(roleBusiness) && window.location.pathname === "/") {

            navigate("/analytics", {replace: true});


        } else if (authManagement.isAuthenticated && authManagement.user?.roles?.includes(roleAdmin) && window.location.pathname === "/") {

            navigate("/admin/dashboard", {replace: true});

        }

        else if (
            authManagement.isAuthenticated &&
            authManagement.user?.roles?.includes(roleBusiness)
        ) {

            navigate("/analytics", {replace: true});

        }

    }, [authManagement.isAuthenticated, navigate]);

    return children;
};

export default AuthAdminGuard;



