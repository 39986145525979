import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { store } from "./store/Store";
import Spinner from "src/components/shared/Spinner";

import "./_mockApis";
import { AuthProvider } from "./routes/guards/AuthContext";

//  TODO:: AFTER STARTUPING THE PROJECT
import setTokenAuth from "src/utils/setTokenAuth";

if (localStorage) {
  setTokenAuth(localStorage.getItem("refresh_token"));
}




const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Suspense fallback={<Spinner />}>
        <BrowserRouter>
          <AuthProvider>
            <App />
          </AuthProvider>
        </BrowserRouter>
      </Suspense>
    </Provider>
  </React.StrictMode>
);
