import {
    GET_REVIEW_STATISTICS,
    GET_REVIEW_HISTORY,
    GET_ALL_REVIEWS,
    SELECT_REVIEW,
    GET_START_AND_END_DATE,
    GET_SELECTED_RATING,
    SET_SENTIMENTS_VISIBILITY,
    GET_SENTIMENTS_VISIBILITY,
    GET_START_AND_END_DATE_GROWTH,
    GET_MINIMUM_DATE_AND_MAXIMUM_DATE_FILTER
} from "src/modules/dashboard/analytics/service/DashboardServiceActionTypes";
import {clearCookies} from "src/utils/cookiesTools";
import {formatDateFromStringToDate} from "../../utils/formatDate";
import {addDays} from "date-fns";

const initialState = {
    topCards: {},
    reviewHistory: {},

    currentPage: 0,
    totalPages: 0,
    totalElements: 0,
    limit: 0,
    reviewList: [],

    selectedReview: {},


    startDate: formatDateFromStringToDate(new Date()),
    endDate: formatDateFromStringToDate(addDays(new Date(), 7)),

    growthStartDate :null,
    growthEndDate :null,



    selectedRating: null,


    // FOR ANALYTICS
    showSentiment: {
        excellent: true,
        bon: true,
        neutre: true,
        negative: true,
        veryNegative: true,
    },

    minimumAndMaximumDateFilter: null


};

export default function DashboardReducer(state = initialState, action) {
    switch (action.type) {
        // GET STATISTICS REVIEW
        case GET_REVIEW_STATISTICS:
            return {
                ...state, topCards: action.payload,
            };

        // GET REVIEW HISTORY
        case GET_REVIEW_HISTORY:
            return {
                ...state, reviewHistory: action.payload,
            };

        // GET ALL REVIEW
        case GET_ALL_REVIEWS:
            return {
                ...state,
                reviewList: action.payload.isNewSearch ? action.payload.reviewList : [...state.reviewList, ...action.payload.reviewList],
                currentPage: action.payload.currentPage,
                totalPages: action.payload.totalPages,
                totalElements: action.payload.totalElements,
                limit: action.payload.limit,

                lastSearchParams: action.payload.searchParams,

            };

        // SELECT REVIEW
        case SELECT_REVIEW:
            return {
                ...state, selectedReview: state.reviewList.find((review) => review.uuid === action.payload),
            };


        // GET START AND END DATE
        case GET_START_AND_END_DATE:
            return {
                ...state, startDate: action.payload.startDate, endDate: action.payload.endDate,
            };

        // GET START AND END DATE FOR GROWTH
        case GET_START_AND_END_DATE_GROWTH:
            return {
                ...state, growthStartDate: action.payload.growthStartDate, growthEndDate: action.payload.growthEndDate,
            };


        // GET_SELECTED_RATING
        case GET_SELECTED_RATING:
            return {
                ...state, selectedRating: action.payload,
            };

        // SENTIMENT VISIBILITY
        case GET_SENTIMENTS_VISIBILITY:
            return {
                ...state, showSentiment: action.payload,
            };
        case SET_SENTIMENTS_VISIBILITY:
            return {
                ...state, showSentiment: action.payload,
            };

            // Minimum And Maximum Date
            case GET_MINIMUM_DATE_AND_MAXIMUM_DATE_FILTER:
                return {
                    ...state, minimumAndMaximumDateFilter: action.payload,
                };

        default: {
            return state;
        }
    }
}
