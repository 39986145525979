import {clearCookies} from "src/utils/cookiesTools";
import {
    GET_MY_BUSINESS, GET_SELECTED_BUSINESS_LOCATION
} from "../../modules/dashboard/analytics/service/DashboardServiceActionTypes";
import {GET_ALL_BUSINESS} from "../../modules/admin/account/service/AccountActionType";

const initialState = {
    myBusiness: {},

    selectedBusinessLocation: null,

    allBusiness: []
};

export default function BusinessReducer(state = initialState, action) {
    switch (action.type) {
        // GET MY BUSINESS
        case GET_MY_BUSINESS:
            return {
                ...state, myBusiness: action.payload,
            };

        case GET_SELECTED_BUSINESS_LOCATION:
            return {
                ...state, selectedBusinessLocation: action.payload,
            };


        default: {
            return state;
        }
    }
}
