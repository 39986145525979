export const GET_ALL_USERS = "GET_ALL_USERS";
export const CREATENEWCUSTOMER = "CREATE_CUSTOMER_BY_ADMIN";
export const NOTIFICATIONSENDED = "NOTIFICATION_SENDED";


export const GET_ALL_BUSINESS = "GET_ALL_BUSINESS";

export const GET_STATISTICS_ADMIN = "GET_STATISTICS_ADMIN";


export const GET_USERS_ONLINE = "GET_USERS_ONLINE";

export const GET_MORE_USERS = "FETCH_MORE_USERS";


export const GET_SELECTED_DATE_FOR_DASHBOARD_ADMIN = "GET_SELECTED_DATE_FOR_DASHBOARD_ADMIN";
