export const iconInInputStyle = {color: "#6c757d", fontSize: "1.2rem"};
export const iconInPreviousStyle = {
    color: "#363f72",
    fontSize: "1.3rem",
    marginRight: "10px",
    matginTop: "10px",
    cursor: "pointer",
    textAlign: "center",
};

export const iconInPreviousInsideComponentsStyleDark = {
    color: "#ffffff",
    marginLeft: "12px",
    marginTop: "15px",
    fontSize: "1.5rem",
    cursor: "pointer",
    textAlign: "center",
};

export const pdfIconConfig = {
    color: "#F40F02",
    fontSize: "1.6rem",
}

export const iconInPreviousInsideComponentsStyleLight = {
    color: "#84665b",
    fontSize: "1.5rem",
    marginTop: "9px",
    cursor: "pointer",
    textAlign: "center",
};

export const excelIconConfig = {
    color: "#34a3a3",
    fontSize: "1.6rem",
    marginRight: "1px",
};

export const iconInModelDialogStyle = {
    color: "#84665b",
    fontSize: "1rem",
    marginRight: "3px",
};
export const iconInModelDialogStyleSupplier = {
    color: "#84665b",
    fontSize: "1.05rem",
    marginRight: "10px",
};
export const iconInModelDialogStyleDeleteTicket = {
    color: "#C70000",
    fontSize: "1.05rem",
    marginRight: "10px",
};
export const iconInModelDialogStyleViewTicket = {
    color: "#84665b",
    fontSize: "1.05rem",
    marginRight: "10px",
};
export const iconInModelDialogSupplierStyle = {
    color: "#ffffff",
    fontSize: "3.3rem",
    marginRight: "3px",
};

export const iconUserInNotification = {
    color: "#ffffff",
    fontSize: "2.5rem",
};
export const InfolIconConfig = {color: "#363f72", fontSize: "1.2rem"};
export const discountIconConfig = {color: "#363f72", fontSize: "1.6rem"};

export const fullWidthIconConfig = {color: "#000000", fontSize: "1.5rem"};

export const sizeChevronUpAndDownInTable = {fontSize: "1.3rem"};
export const menuThreeDots = {fontSize: "1.3rem", color: "#adb5bd"};

export const configChevLeftIcon = {color: "#293056", fontSize: "2.5rem"};
export const calendarIconConfig = {color: "#858897", fontSize: "1.5rem"};
export const calendarIconConfigUpdate = {
    color: "#495057",
    fontSize: "1.5rem",
};

export const socialMediaLink = {
    color: "#fff",
    fontSize: "2rem",
    marginLeft: "20px",
};

export const socialMediaLinkSmall = {
    color: "#fff",
    fontSize: "1.5rem",
    marginLeft: "10px",
    marginRight: "10px",
};

export const iconContactForm = {
    color: "#fff",
    fontSize: "2.5rem",
    marginRight: "10px",
};

export const iconContactFormSmall = {
    color: "#fff",
    fontSize: "3.5rem",
};

export const iconEditPlans = {
    color: "#fff",
    fontSize: "1.2rem",
    marginLeft: "9px",
};

export const iconCopyConfig = {
    color: "#363f72",
    fontSize: "1.8rem",
    marginBottom: "14px",
};

export const iconCloseIcon = {
    color: "#adb5bd",
    fontSize: "1.8rem",
};

export const accountType = [
    {label: "Ho.Re.Ca", value: "HORECA"},
    {label: "Studi di settore", value: "ACCOUNTANT"},
];

export const planMode = [
    {label: "Mensile", value: "MONTHLY"},
    {label: "Annuale", value: "YEARLY"},
];

export const allUsersForRadio = "allUsers";
export const accountTypeForRadio = "accountType";
export const specifiqueUserRadio = "specifiqueUser";
export const horecaType = "HORECA";
export const accountantType = "ACCOUNTANT";

export const guestRoutes = [
    "/auth/confirmation",
    "/auth/account/confirm",
    "/auth/login",
    "/auth/register",
    "/auth/forgot-password",
    "/auth/forgot-password/confirmation",
    "/auth/account/resetPassword",
    "/auth/two-steps",
    "/auth/maintenance",
    "/auth/confirmationMailing",
];

export const routeToGetAllAccountant =
    "/admin/users/management/edit?accountType=ACCOUNTANT";
export const routeToGetAllHoreca =
    "/admin/users/management/edit?accountType=HORECA";

export const succesPayment = "success";
export const cancelPayment = "cancellation";
export const failurePayment = "failure";

export const horecaPlans = "/admin/dashboard/plans/horeca";
export const accountantPlans = "/admin/dashboard/plans/accountant";

// ICON SIDE BAR + HEADER

export const iconMenuHeader = {
    color: "#fff",
    fontSize: "1rem",
};

export const iconNotificationHeader = {
    color: "#fff",
    fontSize: "1.6rem",
};

export const iconProfilHeader = {
    color: "#6c73a2",
    fontSize: "1.6rem",
};

export const iconInSearchStyle = {fontSize: "1.3rem"};
export const iconStarStyle = {fontSize: "1.1rem", color: "#fccc4f"};
export const iconStarUnderStyle = {marginLeft: "6px", fontSize: "1.1rem", color: "#fccc4f"};
export const iconStarReviewContentStyle = {fontSize: "1.1rem", color: "#fccc4f"};


export const iconCloseFilterNotHoverStyle = {
    marginLeft: "6px",
    marginTop: "1px",
    fontSize: "1.4rem",
    color: "transparent"
};
export const iconCloseFilterHoverStyle = {
    marginLeft: "6px",
    marginTop: "1px",
    fontSize: "1.4rem",
    color: "transparent"
};

export const iconFilterHoverStyle = {marginLeft: "6px", marginTop: "1px", fontSize: "1.2rem", color: "#fff"};
export const iconFilterNotHoverStyle = {marginLeft: "6px", marginTop: "1px", fontSize: "1.2rem", color: "#363f72"};


export const iconCalenderFilter = {fontSize: "1.1rem", color: "#344054", marginRight: "20px"};
export const iconCalenderFilterDate = {fontSize: "1.5rem", color: "#344054"};


export const iconForPrevious = {fontSize: "2rem", color: "#363f72"};

export const iconForCardInDashboard = {fontSize: "1rem", color: "#363f72", marginRight: "5px"};


// ROLE IN APPLICATION

export const roleBusiness = "ROLE_BUSINESS";
export const roleAdmin = "ROLE_ADMIN";

// HEADER CELL
// ACCOUNTANT

export const headCellsAdmin = [
    {
        id: "businessInfo",
        numeric: false,
        disablePadding: false,
        label: "Business ",
    },
    {
        id: "businessLocationCount",
        numeric: false,
        disablePadding: false,
        label: "Number Location",
    },

    {
        id: "email",
        numeric: false,
        disablePadding: false,
        label: "email",
    },


    {
        id: "lastLogin",
        numeric: false,
        disablePadding: false,
        label: "Last Login",
    },

    {
        id: "dateJoin",
        numeric: false,
        disablePadding: false,
        label: "Date Join",
    },

    {
        id: "ability",
        numeric: false,
        disablePadding: false,
        label: "account enable",
    },

    {
        id: "lock",
        numeric: false,
        disablePadding: false,
        label: "Lock",
    },


    {
        id: "updateFreeTrial",
        numeric: false,
        disablePadding: false,
        label: "Free Trial",
    },

    {
        id: "updateInfo",
        numeric: false,
        disablePadding: false,
        label: "Update Info",
    },


];


export const limitForAllDropDown = 15;
// TODO:: REMOVE AFTER TEST
export const limitForAllDropDownForBusiness = 1;


export const Tutte = "Tutte"

export const TOTAL_REVIEWS = 'totalReviews';


export const ADVANCED_FILTER_FILTRA_CUSTOME = "ADVANCED_FILTER_FILTRA_CUSTOME"
export const ADVANCED_FILTER_CONFORTA_CUSTOME = "ADVANCED_FILTER_CONFORTA_CUSTOME"


export const GET_SEVEN_DAYS_COUNT_BY_TODAY = "GET_SEVEN_DAYS_COUNT_BY_TODAY";
export const GET_28_DAYS_COUNT_BY_TODAY = "GET_28_DAYS_COUNT_BY_TODAY";
export const GET_3_MONTHS_COUNT_BY_TODAY = "GET_3_MONTHS_COUNT_BY_TODAY";
export const GET_6_MONTHS_COUNT_BY_TODAY = "GET_6_MONTHS_COUNT_BY_TODAY";
export const GET_12_MONTHS_COUNT_BY_TODAY = "GET_12_MONTHS_COUNT_BY_TODAY";
export const GET_16_MONTHS_COUNT_BY_TODAY = "GET_16_MONTHS_COUNT_BY_TODAY";
export const CUSTOME_DATE = "CUSTOME_DATE";


export const GET_SEVEN_DAYS_COUNT_BY_TODAY_COMPARE = "GET_SEVEN_DAYS_COUNT_BY_TODAY_COMPARE";
export const GET_28_DAYS_COUNT_BY_TODAY_COMPARE = "GET_28_DAYS_COUNT_BY_TODAY_COMPARE";
export const GET_3_MONTHS_COUNT_BY_TODAY_COMPARE = "GET_3_MONTHS_COUNT_BY_TODAY_COMPARE";
export const GET_6_MONTHS_COUNT_BY_TODAY_COMPARE = "GET_6_MONTHS_COUNT_BY_TODAY_COMPARE";
export const GET_12_MONTHS_COUNT_BY_TODAY_COMPARE = "GET_12_MONTHS_COUNT_BY_TODAY_COMPARE";
export const GET_16_MONTHS_COUNT_BY_TODAY_COMAPRE = "GET_16_MONTHS_COUNT_BY_TODAY_COMAPRE";
export const CUSTOME_DATE_COMPARE = "CUSTOME_DATE_COMPARE";








