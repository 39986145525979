export const GET_REVIEW_STATISTICS = "GET_REVIEW_STATISTIQUE";
export const GET_REVIEW_HISTORY = "GET_REVIEW_HISTORY";
export const GET_ALL_REVIEWS = "GET_ALL_REVIEW";
export const SELECT_REVIEW = "SELECT_REVIEW";


export const GET_MY_BUSINESS = "GET_MY_BUSINESS";
export const GET_SELECTED_BUSINESS_LOCATION = "GET_SELECTED_BUSINESS_LOCATION";


export const GET_MY_CATEGORY = "GET_MY_CATEGORY";
export const GET_SELECTED_CATEGORY = "GET_SELECTED_CATEGORY";

export const GET_START_AND_END_DATE = "GET_START_AND_END_DATE";
export const GET_START_AND_END_DATE_GROWTH = "GET_START_AND_END_DATE_GROWTH";
export const GET_SELECTED_RATING = "GET_SELECTED_RATING";


export const GET_ALL_TOPICS = "GET_ALL_TOPICS";
export const GET_SELECTED_TOPIC = "GET_SELECTED_TOPIC";

export const GET_SENTIMENTS_VISIBILITY = "GET_SENTIMENTS_VISIBILITY";

export const SET_SENTIMENTS_VISIBILITY = 'SET_SENTIMENTS_VISIBILITY';


export const GET_MINIMUM_DATE_AND_MAXIMUM_DATE_FILTER = "GET_MINIMUM_DATE_AND_MAXIMUM_DATE_FILTER";
