import dayjs from "dayjs";
// Day.js plugin for advanced formatting
import customParseFormat from "dayjs/plugin/customParseFormat";
import "dayjs/locale/it"; // Load Italian locale
import relativeTime from "dayjs/plugin/relativeTime"; // Load relative time plugin

export const formatToDate = (inputDate) => {
    dayjs.extend(customParseFormat);

    // Parse and format the date
    const date = dayjs(inputDate, "ddd, DD MMM YYYY HH:mm:ss [GMT]");

    // Format the date to 'YYYY-MM-DD'
    return date.format("YYYY-MM-DD");
};

export const formatDateAgo = (dateToFormat) => {
    // Extend dayjs with relative time plugin
    dayjs.extend(relativeTime);
    // Set locale to Italian
    dayjs.locale("it");
    // Format the provided date string into a human-readable relative time
    return dayjs(dateToFormat).fromNow();
};

export const formatItalianDate = (inputDate) => {
    // Set locale to Italian
    dayjs.locale("it");

    return dayjs(inputDate).format("dddd MMMM D, YYYY H:mm");
};

export const setFromDateIfNull = (from) => {
    // Check if 'from' is null, if so, set it to '1970-01-01'
    return from ? dayjs(from).format("YYYY-MM-DD") : "1970-01-01";
};

export const setToDateIfNull = (to) => {
    // Set 'to' date to today's date if it's null
    return to ? dayjs(to).format("YYYY-MM-DD") : dayjs().format("YYYY-MM-DD");
};

export const formatDateToSentence = (dateToFormat) => {
    return dayjs(dateToFormat).locale("it").format("DD MMMM YYYY");
};

export const formatDateItalianShort = (inputDate) => {
    // Parse the input date using dayjs
    const parsedDate = dayjs(inputDate);

    // Format the date as "DD MMM" (e.g., "28 AVR")
    const formattedDate = parsedDate.format("DD MMM hh:mm");

    return formattedDate;
};

export const formatDateToNeedOfCardBusinessInAdminDashboard = (inputDate) => {
    // Parse the input date using dayjs
    const parsedDate = dayjs(inputDate);

    const formattedDate = parsedDate.format("DD MMM YYYY hh:mm");

    return formattedDate;
};

export const convertDateToItalianFormatShort = (dateString) => {
    return dayjs(dateString).format('D MMM');
};

export const convertDateToItalianFormatLong = (dateString) => {
    return dayjs(dateString).format('D MMM YYYY');
};

export function formatDateToSense(dateString) {
    const date = dayjs(dateString);
    return date.format('MMM DD, YYYY');
}


export const formatDateFromStringToDate = (dateString) => {
    // Parse the input date string
    const date = dayjs(dateString);

    // Format the date to "YYYY-MM-DD"
    return date.format('YYYY-MM-DD');
}
