import {configureStore} from "@reduxjs/toolkit";
import CustomizerReducer from "./customizer/CustomizerSlice";
import EmailReducer from './email/EmailSlice';
import AuthReducer from "./AuthReducer";
import DashboardReducer from "./dashboard/DashboardReducer";
import BusinessReducer from "./business/BusinessReducer";
import CategoryReducer from "./category/CategoryReducer";
import AccountManagementReducer from "./business/BusinessReducer";
import AdminAccountReducer from "./admin/AdminAccountReducer";
import TopicReducer from "./topic/TopicReducer";

export const store = configureStore({
    reducer: {
        customizer: CustomizerReducer,
        emailReducer: EmailReducer,
        authentication: AuthReducer,
        dashboardReducer: DashboardReducer,
        businessReducer: BusinessReducer,
        categoryReducer: CategoryReducer,
        accountManagementReducer: AccountManagementReducer,
        adminAccountReducer: AdminAccountReducer,
        topicReducer: TopicReducer,
    },
});

export default store;
