import {
    GET_ALL_BUSINESS,
    GET_ALL_USERS,
    GET_MORE_USERS,
    GET_SELECTED_DATE_FOR_DASHBOARD_ADMIN,
    GET_STATISTICS_ADMIN,
    GET_USERS_ONLINE
} from "src/modules/admin/account/service/AccountActionType";
import {getStatisticUser} from "../../modules/admin/account/service/AccountService";
import {formatDateFromStringToDate} from "../../utils/formatDate";
import {addDays} from "date-fns";

const initialState = {


    // ALL USERS
    currentPage: 0,
    totalPages: 0,
    totalElements: 0,
    userList: [],

    // BUSINESS LIST
    businessList: [],
    currentPageBusiness: 0,
    totalPagesBusiness: 0,
    totalElementsBusiness: 0,

    // STATISTICS
    usersStatistics: {},

    // LIST OF ONLINE USERS
    currentPageOnlineUser: 0,
    totalPagesOnlineUser: 0,
    totalElementsOnlineUser: 0,
    userListOnlineUser: [],

    startDate: formatDateFromStringToDate(addDays(new Date(),-365) ),
    endDate: formatDateFromStringToDate(new Date()),


};

export default function AdminAccountReducer(state = initialState, action) {
    switch (action.type) {
        case GET_ALL_USERS:
            return {
                ...state,
                currentPage: action.payload.currentPage,
                totalPages: action.payload.totalPages,
                totalElements: action.payload.totalElements,
                userList: action.payload.data,
            };

        case GET_ALL_BUSINESS:
            return {
                ...state,
                currentPageOnlineUser: action.payload.currentPage,
                totalPagesOnlineUser: action.payload.totalPages,
                totalElementsOnlineUser: action.payload.totalElements,
                businessList: action.payload.data,
            };

        case GET_STATISTICS_ADMIN:
            return {
                ...state, usersStatistics: action.payload,
            };


        case GET_USERS_ONLINE:
            return {
                ...state,
                currentPageBusiness: action.payload.currentPage,
                totalPagesBusiness: action.payload.totalPages,
                totalElementsBusiness: action.payload.totalElements,
                // Reset list if it's a new search ( when on page 0)
                userListOnlineUser: action.payload.currentPage === 0
                    ? action.payload.data
                    : [...state.userListOnlineUser, ...action.payload.data],
            };

        case GET_SELECTED_DATE_FOR_DASHBOARD_ADMIN:
            return {
                ...state, startDate: action.payload.startDate, endDate: action.payload.endDate,
            }

        default: {
            return state;
        }
    }
}
